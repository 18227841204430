import React, { useContext, useEffect, useRef, useState } from 'react';
import Profile1 from '../assets/logo-symbol.webp'
import Icons from '../components/Icons';
import Avatar from '../assets/Avatar.webp'
import Coin from '../assets/coin.png'

import telegramIconSmall from '../assets/cgpt-telegram_small.webp'
import coinIconSmall from '../assets/cgpt-coins_small.webp'
import Box from '../assets/subscribe.png'
import { useGSAP } from '@gsap/react';
import gsap from 'gsap/all';
import { UserContext } from '../contexts/userContext';
import QuestModal from './QuestModal';

const QuestsPage = ({ name, handleButtonEvents, handleValChange, handleProfileView }) => {
    const mainContainer = useRef();
    const { tasks, taskHandler, isConnected, userId, isMobile } = useContext(UserContext);
    const [selectedTask, setSelectedTask] = useState(tasks.length > 0 ? tasks[0] : {});
    const [rerender2, setRerender2] = useState(false);

    const { contextSafe } = useGSAP({ dependencies: [tasks], scope: mainContainer });
    const handlePointerEvents = contextSafe((type, object, taskObject = {}) => {
        object.preventDefault();
        
        // console.log("button clicked")
        if (type == "close task page") {
            gsap.fromTo(".tasks-page", { autoAlpha: 1, }, { autoAlpha: 0, duration: 0.5, ease: "power2.inOut" });
            gsap.fromTo(".modal-quests", { yPercent: 0 }, { yPercent: 100, duration: 0.5, ease: "power2.inOut" });
            
        } else if (type == "open task page") {
            // console.log("opening task")
            setSelectedTask(taskObject);
            setRerender2(!rerender2);
            gsap.fromTo(".tasks-page", { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
            gsap.fromTo(".modal-quests", { yPercent: 100 }, { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
        } else if (type == "success claim") {
            handleValChange("task rewards no handler", selectedTask);
            gsap.fromTo(".tasks-page", { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.5, ease: "power2.inOut" });
            gsap.fromTo(".modal-quests", { yPercent: 0 }, { yPercent: 100, duration: 0.5, ease: "power2.inOut" });
        }

    })

    useGSAP(() => {
        gsap.fromTo(".tasks-page", { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.002, ease: "none" });
        gsap.fromTo(".modal-quests", { yPercent: 0 }, { yPercent: 100, duration: 0.002, ease: "none" });
    }, { dependencies: [tasks], scope: mainContainer })

    useEffect(() => {
        if (isConnected && isMobile) taskHandler("Fetch");
    }, [isConnected, isMobile])

    return (
        <div ref={mainContainer} className={` w-full h-dvh flex flex-col gap-4 px-4 py-4 relative z-[1]`}>
            <div className="w-full h-[2rem] flex justify-between z-[8]">
                <div className="h-full aspect-square">
                    <Icons name="Logo" className="size-full" />
                </div>
                <div  onClick={(e) => handleProfileView("open profile page", e)} className='flex h-full items-center justify-end gap-2'>
                    <div className="h-full aspect-square">
                        <Icons name="Photo Frame" className="size-full" imagesrc={Profile1} />
                    </div>
                    <h1 className="text-white font-['Roboto_Mono'] uppercase text-[12px]">{name}</h1>
                </div>
            </div>
            <div className="w-full flex-col justify-start items-start gap-2 inline-flex relative blur-bg z-[8]">
                <div className="bg-card-1 absolute top-0 left-0 size-full flex justify-center items-center z-[1] ">
                    <div className="size-full relative bg-card-2 opacity-50"></div>
                </div>
                <div className="w-full  flex-col justify-start items-start flex px-4 py-4 relative z-[2]">
                    <div className="w-full flex-col justify-start items-start gap-2 flex font-['Violet_Sans']">
                        <div className="w-full flex-col justify-center items-start gap-2.5 flex">
                            <div className="px-2 py-1 bg-[#f8cf3f]/10 rounded-sm justify-start items-start gap-2.5 inline-flex">
                                <div className="text-center text-[#27AE60] text-[13px] font-normal ">Available</div>
                            </div>
                        </div>
                        <div className="w-full text-[#efefe5] text-[30px] font-normal  leading-9">{`${tasks.length > 0 ? tasks.reduce((acc, cur) => cur.status !== "Claimed" ? ++acc : acc, 0) : '0'} TASK${tasks.length > 0 ? tasks.reduce((acc, cur) => cur.status !== "Claimed" ? ++acc : acc, 0) > 1 ? 'S' : '' : ''}`}</div>

                    </div>

                </div>
            </div>
            <div className='overflow-scroll pb-[5rem] flex flex-col gap-4 '>

                {tasks.length > 0 && tasks.map((task, idx) => (
                    <div key={`task-${idx}`} className="flex gap-3 w-full py-3 px-4 items-center bg-[#111115] border-light rounded-[3px]">
                        <div className="w-[3rem] relative p-1 aspect-square border-light">
                            <img src={task.platform == "telegram" ? telegramIconSmall : coinIconSmall} className='size-full object-contain object-center' />
                        </div>
                        <div className="flex flex-col grow shrink basis-0 justify-start items-start p-2">
                            <p className="text-[#EFEFE5]/60 text-[11px]">{task.title}</p>
                            <p className='text-[#27AE60] text-base'>+{task.rewards}</p>
                        </div>
                        <button id={`check-${task.id}`} onClick={(e) => handlePointerEvents("open task page", e, task)} className={`${task.status != "Claimed" ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-50'} relative w-[6rem] check-task-button`}>
                            <div className="absolute top-0 left-0 size-full btn-cgpt-rounded"><div className="btn-cgpt-short relative size-full "></div></div>
                            <div className="absolute top-0 left-0 size-full btn-cgpt-rounded-2"><div className="btn-cgpt-2 relative size-full "></div></div>
                            {/* <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <filter id="flt_tag">
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                        <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                                    </filter>
                                    <filter id="flt_tag_2">
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" result="blur" />
                                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                        <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                                    </filter>
                                </defs>
                            </svg> */}
                            <div className="w-full relative py-3 px-6 flex justify-center items-center gap-2">
                                <p className="text-white text-sm font-['Roboto_Mono']">{task.status == "Claimed" ? 'Claimed' : 'Check'}</p>
                            </div>
                        </button>
                    </div>
                ))}




            </div>
            <div className="tasks-page w-full fixed top-0 left-0 bg-[#09090E] bg-opacity-80 h-full z-[12] p-4 opacity-100">
                <div className="relative size-full modal-quests flex justify-center items-center">
                    {tasks.length > 0 && <QuestModal task={selectedTask} rerender={rerender2} handleButtonEvents={handlePointerEvents} handleValChange={handleValChange} />}
                </div>

            </div>
        </div>
    );
}

export default QuestsPage;
