import React from "react";
import Robot0 from "../assets/robots/00-default.webp";
import Robot1 from "../assets/robots/01.webp";
import Robot2 from "../assets/robots/02.webp";
import Robot3 from "../assets/robots/03.webp";
import Robot4 from "../assets/robots/04.webp";
import Robot5 from "../assets/robots/05.webp";
import Robot6 from "../assets/robots/06.webp";
import Robot7 from "../assets/robots/07.webp";
import Robot8 from "../assets/robots/08.webp";
import Robot9 from "../assets/robots/09.webp";
import Robot10 from "../assets/robots/10.webp";
import Robot9Drone from "../assets/robots/09_drone.webp";
import Robot10Drone from "../assets/robots/10_drone.webp";

const Characters = ({ level, className }) => {
    
    return (
        <>
            {(() => {
                switch (level.toString()) {
                    case "0":
                        return (
                            <div className={className}>
                                <img src={Robot0} className='size-full object-contain object-bottom' />
                            </div>
                        )
                    case "1":
                        return (
                            <div className={className}>
                                <img src={Robot1} className='size-full object-contain object-bottom' />
                            </div>
                        )
                    case "2":
                        return (
                            <div className={className}>
                                <img src={Robot2} className='size-full object-contain object-bottom' />
                            </div>
                        )

                    case "3":
                        return (
                            <div className={className}>
                                <img src={Robot3} className='size-full object-contain object-bottom' />
                            </div>
                        )
                    case "4":
                        return (
                            <div className={className}>
                                <img src={Robot4} className='size-full object-contain object-bottom' />
                            </div>

                        )
                    case "5":
                        return (
                            <div className={className}>
                                <img src={Robot5} className='size-full object-contain object-bottom' />
                            </div>
                        )
                    case "6":
                        return (
                            <div className={className}>
                                <img src={Robot6} className='size-full object-contain object-bottom' />
                            </div>
                        )
                    case "7":
                        return (
                            <div className={className}>
                                <img src={Robot7} className='size-full object-contain object-bottom' />
                            </div>
                        )
                    case "8":
                        return (
                            <div className={className}>
                                <img src={Robot8} className='size-full object-contain object-bottom' />
                            </div>
                        )
                    case "9":
                        return (
                            <div className={className}>
                                <img src={Robot9} className='size-full object-contain object-bottom' />
                                <div className="absolute right-[5%] top-10 w-[5rem] aspect-square animate-up-and-down">
                                    <img src={Robot9Drone} className='size-full object-contain object-bottom' />
                                </div>
                            </div>
                        )
                    case "10":
                        return (
                            <div className={className}>
                                <img src={Robot10} className='size-full object-contain object-bottom' />
                                <div className="absolute right-[5%] top-10 w-[5rem] aspect-square animate-up-and-down">
                                    <img src={Robot10Drone} className='size-full object-contain object-bottom' />
                                </div>
                            </div>
                        )
                    case "11":
                        return (
                            <></>
                        )

                }
            })()}

        </>
    );
};

export default Characters;
