import React from "react";
import QR from "../assets/QR_bot.webp";


const QRCode = () => {
  return (
    <section className="w-full h-[100dvh] text-[#fff] bg-colour z-[1] px-3 relative overflow-hidden flex flex-col justify-center items-center gap-6">

      <div className="w-full h-[60vh] ">
        <img src={QR} className="w-full h-full object-contain object-center px-20" />
      </div>
      <div className="w-full flex flex-col gap-4 justify-center items-center">
        <h1 className="text-white text-xl text-center w-2/3">For the best experience, play on your phone.</h1>
        <h1 className="text-[#898989] text-xs text-center px-20 font-light">Please switch to your mobile device and scan the QR code to play.</h1>
      </div>
    </section>
  );
};

export default QRCode;
