import React, { useContext, useEffect, useRef, useState } from 'react';
import Icons from '../components/Icons';

import Profile1 from '../assets/logo-symbol.webp'
import BNBLogo from '../assets/BNB.svg'
import { UserContext } from '../contexts/userContext';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap/all';

import ReactGA from 'react-ga4';

const ProfilePage = ({ name, handleProfileView }) => {
    const { profile, profileHandler, isConnected, isMobile } = useContext(UserContext);
    const mainContainer = useRef();
    const walletAddress = useRef();
    const fullName = useRef();
    const emailAddress = useRef();
    const userName = useRef();
    const [countData, setCountData] = useState(0);
    const newDataProfile = useRef([]);
    const warningMessage1 = useRef();
    const warningMessage2 = useRef();
    const warningMessage3 = useRef();
    const warningMessage4 = useRef();

    const { contextSafe } = useGSAP({ scope: mainContainer, revertOnUpdate: true });
    const handlePointerEvents = contextSafe((type, object, taskObject = {}) => {
        object.preventDefault();
        if (type == "save profile") {
            const dataName = fullName.current.value === profile.full_name ? null : fullName.current.value.length > 0 ? { full_name: fullName.current.value } : null;
            const dataEmail = emailAddress.current.value === profile.email ? null : emailAddress.current.value.length > 0 ? { email: emailAddress.current.value } : null;
            const dataWallet = walletAddress.current.value === profile.wallet ? null : walletAddress.current.value.length > 0 ? { wallet: walletAddress.current.value } : null;
            setCountData(0);
            let dataProfile = [dataName, dataEmail, dataWallet];
            newDataProfile.current = dataProfile.filter(value => value != null)
            console.log(newDataProfile);
            dataProfile.map((data, idx) => {
                if (data) {
                    let saveRequest = profileHandler("Change Profile", data);
                    saveRequest.then((res) => {
                        setCountData(countData + 1);
                        console.log(countData)
                    }).catch((error) => {
                        // console.log(error)
                        switch (idx) {
                            case 0:
                                warningMessage1.current.innerText = "Error setting name";
                                break;
                            case 1:
                                warningMessage2.current.innerText = "Invalid email";
                                break;
                            case 2:
                                warningMessage3.current.innerText = "Invalid Address";
                                break;

                        }
                    })
                } else {
                    switch (idx) {
                        case 0:
                            if (fullName.current.value !== profile.full_name) {
                                warningMessage1.current.innerText = "Please input a name";
                            }
                            break;
                        case 1:
                            if (emailAddress.current.value !== profile.email) {
                                warningMessage2.current.innerText = "Please input an email";
                            }
                            break;
                        case 2:
                            if (walletAddress.current.value !== profile.wallet) {
                                warningMessage3.current.innerText = "Please input your wallet address";
                            }
                            break;

                    }
                }
            })
            console.log(countData)




        } else if ("changed") {
            if (fullName.current.value === profile.full_name && emailAddress.current.value === profile.email && walletAddress.current.value === profile.wallet) {
                gsap.to("#profile-save-button", { opacity: 0.5, pointerEvents: "none", duration: 0.2 });
            } else {
                gsap.to("#profile-save-button", { opacity: 1, pointerEvents: "auto", duration: 0.2 });
            }
        }
    })
    useEffect(() => {
        const checkProfile = setInterval(() => {
            if (countData != 0 && countData == newDataProfile.current.length) {
                profileHandler("Fetch");
                ReactGA.event("add_info", {
                    category: "Profile",
                    full_name: fullName.current.value,
                    email_address: emailAddress.current.value,
                    wallet_address: walletAddress.current.value,
                    label: "User updated the profile page", // optional
                  })
                handleProfileView("close profile page");
                setCountData(0);
            }
        }, 200);
        return () => clearInterval(checkProfile)
    }, [profileHandler, countData])


    useEffect(() => {
        if (isConnected && isMobile) profileHandler("Fetch");
        gsap.to("#profile-save-button", { opacity: 0.5, pointerEvents: "none", duration: 0.2 });
    }, [isConnected, isMobile])

    return (
        <div ref={mainContainer} className='w-full h-dvh flex flex-col gap-4 px-4 py-4 bg-colour relative z-[1] select-none'>
            <div className="w-full h-[2rem] flex justify-between relative">
                <div onClick={(e) => handleProfileView("close profile page", e)} className="h-full aspect-square">
                    <Icons name="Logo" className="size-full" />
                </div>
                <div className='flex h-full items-center justify-end gap-2'>
                    <div className="h-full aspect-square">
                        <Icons name="Photo Frame" className="size-full" imagesrc={Profile1} />
                    </div>
                    <h1 className="text-white font-['Roboto_Mono'] uppercase text-[12px]">{name}</h1>
                </div>
            </div>

            <div className="w-full h-full flex flex-col relative gap-2 py-2 px-6 ">
                <div className="bg-card-1 absolute top-0 left-0 size-full flex justify-center items-center z-[1] ">
                    <div className="size-full relative bg-card-2 opacity-100"></div>
                </div>
                <div className="w-full relative z-[2] py-4 flex flex-col gap-2 justify-start items-start">
                    <div className="absolute top-0 right-0 w-full flex justify-end py-8 px-2 z-[5]">
                        <h1 onClick={(e) => { handleProfileView("close profile page", e) }} className="pointer-events-auto text-sm text-center  text-[#efefe5] font-['Roboto_Mono'] uppercase tracking-[-0.5px]">Back</h1>
                    </div>
                    <div className="h-[60px] aspect-square my-3">
                        <Icons name="Photo Frame" className="size-full" imagesrc={Profile1} />
                    </div>

                    <div className="flex flex-col justify-start items-start w-full relative">
                        <h1 className="text-white font-['Violet_Sans'] text-sm mb-1">Full Name</h1>
                        <div className="relative w-full flex flex-col justify-start items-start gap-2">
                            <input onChange={(e) => { handlePointerEvents("change", e) }} ref={fullName} type="text" id="full-name" name="full-name" placeholder={profile.full_name ? 'Enter Your Name' : profile.full_name} defaultValue={profile.full_name ? profile.full_name : null} className="bg-[#121217] border border-[#353539] text-[#969692] focus:text-white active:text-white text-sm font-['Violet_Sans'] rounded-xl active:ring-0 active:border-[#353539] outline-none focus:ring-0 focus:border-[#353539] block w-full p-2.5 " />
                        </div>
                        <p ref={warningMessage1} className="warning-message mt-1 text-xs text-red-500 text-[12px] leading-1 "></p>
                    </div>
                    <div className="flex flex-col justify-start items-start w-full ">
                        <h1 className="text-white font-['Violet_Sans'] text-sm mb-1">Email</h1>
                        <div className="relative w-full flex flex-col justify-start items-start gap-2">
                            <input onChange={(e) => { handlePointerEvents("change", e) }} ref={emailAddress} type="email" id="email-address" name="email-address" placeholder={profile.email ? 'Enter Your Email' : profile.email} defaultValue={profile.email ? profile.email : null} className="bg-[#121217] border border-[#353539] text-[#969692] focus:text-white active:text-white text-sm font-['Violet_Sans'] rounded-xl active:ring-0 active:border-[#353539] outline-none focus:ring-0 focus:border-[#353539] block w-full p-2.5 " />
                        </div>
                        <p ref={warningMessage2} className="warning-message mt-1 text-xs text-red-500 text-[12px] leading-1 "></p>
                    </div>
                    <div className="flex flex-col justify-start items-start w-full ">
                        <h1 className="text-white font-['Violet_Sans'] text-sm mb-1">EVM Wallet Address</h1>
                        <div className="relative w-full flex flex-col gap-1 justify-start items-start">
                            <div className="absolute w-full h-full pointer-events-none p-2.5">
                                <img src={BNBLogo} alt="" className=' h-full aspect-square object-contain object-center' />
                            </div>
                            <input onChange={(e) => { handlePointerEvents("change", e) }} ref={walletAddress} type="text" id="wallet-address" name="wallet-address" placeholder={profile.wallet ? 'Enter Your Wallet Address' : profile.wallet} defaultValue={profile.wallet ? profile.wallet : null} className="bg-[#121217] border border-[#353539] text-[#969692] focus:text-white active:text-white text-sm font-['Violet_Sans'] rounded-xl active:ring-0 active:border-[#353539] outline-none focus:ring-0 focus:border-[#353539] block w-full py-2.5 pr-2.5 pl-[48px]" />

                        </div>
                        <p ref={warningMessage3} className="warning-message mt-1 text-xs text-red-500 text-[12px] leading-1 "></p>
                    </div>
                    <div className="flex flex-col justify-start items-start w-full ">
                        <h1 className="text-white font-['Violet_Sans'] text-sm mb-1">Username</h1>
                        <div className="relative w-full flex flex-col justify-start items-start gap-2">
                            <h1 ref={userName} id="user-name" className="bg-[#121217] select-text border border-[#353539] text-[#969692] text-sm font-['Violet_Sans'] rounded-xl outline-none block w-full p-2.5 " >@{profile.username ? profile.username : name}</h1>
                        </div>
                        <p ref={warningMessage4} className="warning-message mt-1 text-xs text-red-500 text-[12px] leading-1 "></p>
                    </div>

                </div>
                <button id="profile-save-button" onClick={(e) => handlePointerEvents("save profile", e)} className={` relative w-full z-[4] pointer-events-auto`}>
                    <div className="absolute top-0 left-0 size-full btn-cgpt-rounded"><div className="btn-modal-cgpt-short relative size-full "></div></div>
                    <div className="absolute top-0 left-0 size-full btn-cgpt-rounded-2"><div className="btn-modal-cgpt-2 relative size-full "></div></div>
                    {/* <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <filter id="flt_tag">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                            </filter>
                            <filter id="flt_tag_2">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                            </filter>
                        </defs>
                    </svg> */}
                    <div className="w-full relative py-4 px-6 flex justify-center items-center gap-2">
                        <p className="text-white text-sm font-['Roboto_Mono']">{`Save Changes`}</p>
                    </div>
                </button>
            </div>

        </div>
    );
}

export default ProfilePage;
