import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import Icons from '../components/Icons';
import Avatar from '../assets/Avatar.webp'
import Coin from '../assets/coin.png'
import Profile1 from '../assets/sample1.png'
import ProfileMain from '../assets/logo-symbol.webp'
import Profile2 from '../assets/sample2.png'
import Profile3 from '../assets/sample3.png'
import Box from '../assets/subscribe.png'
import { useGSAP } from '@gsap/react';
import gsap from 'gsap/all';
import { sampleUsers } from '../constants';
import { formatNumber, scrollParentToChild } from '../scripts/utils';
import { UserContext } from '../contexts/userContext';

const LeadersPage = ({ name, cgpt, users, handleProfileView }) => {
    const { leaderboards, userId, leaderHandler, isConnected, isMobile } = useContext(UserContext);
    const mainContainer = useRef();
    const top3 = useRef();
    const lastValue = useRef(cgpt.value);
    const [rerender, setRerender] = useState(false)
    useGSAP(() => {
        if (leaderboards.length > 0) {
            const userIdx = leaderboards.findIndex(x => x.id === userId);
            const childDiv = document.getElementById(`leaderboard-${userIdx}`);
            const parentDiv = document.getElementById("leaderboard-container");

            if (userIdx >= 3) {
                if (parentDiv) {
                    parentDiv.scrollTop = 0;
                }
            } else {
                if (parentDiv && childDiv) {
                    scrollParentToChild(parentDiv, childDiv)
                }
            }
        }

        // scrollParentToChild()
    }, { dependencies: [leaderboards], scope: mainContainer, revertOnUpdate: true })





    useEffect(() => {
        if (isConnected && isMobile) leaderHandler();
        // console.log("leaderboard rerendered");
    }, [isConnected, isMobile])

    return (
        <div ref={mainContainer} className='w-full h-dvh flex flex-col relative z-[1]'>
            <div ref={top3} className="relative w-full z-[4] ">
                <div className="flex flex-col gap-4 w-full px-4 py-4 rounded-b-2xl relative top-0 left-0 bg-blur-before">
                    <div className="w-full h-[2rem] flex justify-between z-[8]">
                        <div className="h-full aspect-square">
                            <Icons name="Logo" className="size-full" />
                        </div>
                        <div onClick={(e) => handleProfileView("open profile page", e)} className='flex h-full items-center justify-end gap-2'>
                            <div className="h-full aspect-square">
                                <Icons name="Photo Frame" className="size-full" imagesrc={ProfileMain} />
                            </div>
                            <h1 className="text-white font-['Roboto_Mono'] uppercase text-[12px]">{name}</h1>
                        </div>
                    </div>
                    <div className="w-full flex-col justify-start items-start gap-2 inline-flex relative z-[8]">
                        {leaderboards.length > 0 && leaderboards.map((leaderboard, idx) => {
                            if (idx < 3) {
                                return (
                                    <div key={`top-${idx}`} id={`leaderboard-${idx}`} className={`flex justify-center items-center w-full gap-4 px-4 py-4 relative ${leaderboard.id == userId ? 'border-gradient' : 'border-light'} bg-[#202027]`}>
                                        <div className={`h-[18px] aspect-square rounded-full flex justify-center items-center bg-gradient-to-b ${idx == 0 ? 'from-[#fef128] to-[#d4990e] border-[#fef128]' : idx == 1 ? 'from-white to-[#90908f] border-white' : 'from-[#e7d4c6] to-[#8d7c6f] border-[#e7d4c6]'} border-2`}>
                                            <h1 className="text-black font-['Violet_Sans'] text-sm">{leaderboard.rank}</h1>
                                        </div>
                                        <div className="w-6 h-6 rounded-full overflow-hidden border border-[#353539] justify-center items-center inline-flex">
                                            <img className="w-6 h-6 rounded-full object-cover object-center" src={ProfileMain} />
                                        </div>
                                        <div className="grow shrink basis-0">
                                            <h1 className="text-white text-sm truncate">{leaderboard.name ? leaderboard.name : leaderboard.id}</h1>
                                        </div>
                                        <div className="flex items-center justify-end"><span className="text-[#efefe5]/60 text-[11px] font-normal font-['Violet_Sans'] leading-[1]">LVL <span>{leaderboard.level}</span> / </span><span className="text-[#efefe5] text-[11px]  leading-[1] font-normal font-['Violet_Sans'] pl-1">{formatNumber(leaderboard.points)}</span></div>
                                    </div>
                                )
                            }
                        })
                        }
                    </div>
                </div>
            </div>


            <div id="leaderboard-container" className='overflow-scroll pb-[6rem] w-full h-full z-[2] leaderboard relative flex flex-col justify-start gap-4 px-4 py-4'>
                {leaderboards.length > 0 && leaderboards.map((leaderboard, idx) => {
                    if (idx >= 3) {
                        return (
                            <div key={`leaderboard-${idx}`} id={`leaderboard-${idx}`} className={`flex justify-center items-center w-full gap-4 px-4 py-4 relative ${leaderboard.id != userId ? "border-light bg-[#121217]" : "bg-[#111115] border-gradient"}`}>
                                <div className="h-[18px] aspect-square rounded-full flex justify-start items-center">
                                    <h1 className="text-[#efefe5]/60 font-['Violet_Sans']">{leaderboard.rank < 10 ? `0${leaderboard.rank}` : leaderboard.rank}</h1>
                                </div>
                                <div className="w-6 h-6 rounded-full overflow-hidden border border-[#353539] justify-center items-center inline-flex">
                                    <img className="w-6 h-6 rounded-full object-cover object-center" src={ProfileMain} />
                                </div>
                                <div className="grow shrink basis-0">
                                    <h1 className="text-white text-sm truncate">{leaderboard.name ? leaderboard.name : leaderboard.id}</h1>
                                </div>
                                <div className="flex items-center justify-end"><span className="text-[#efefe5]/60 text-[11px] font-normal font-['Violet_Sans'] leading-[1]">LVL <span>{leaderboard.level}</span> / </span><span className="text-[#efefe5] text-[11px]  leading-[1] font-normal font-['Violet_Sans'] pl-1">{formatNumber(leaderboard.points)}</span></div>
                            </div>
                        )
                    }
                }
                )}

            </div>
        </div>
    );
}

export default LeadersPage;
