import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { UserContextProvider } from "./contexts/userContext.jsx";
import WebApp from '@twa-dev/sdk';

import './scripts/instrument.js' // Sentry

WebApp.ready();
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </React.StrictMode>
);