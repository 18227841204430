export const clamp = (val, min = 0, max = 1) => Math.max(min, Math.min(max, val))
export const mod = (n, m) => {
    return ((n % m) + m) % m;
}


export const formatNumber = (number = 0) => {
    if (number == null) {
        return "0";
    } else {
        if (number >= 1000000000000) return `${(number / 1000000000000).toFixed(2)}T`;
        else if (number >= 1000000000) return `${(number / 1000000000).toFixed(2)}B`;
        else if (number >= 1000000) return `${(number / 1000000).toFixed(2)}M`;
        else if (number >= 1000) return `${(number / 1000).toFixed(2)}K`;
        else return `${number.toLocaleString()}`;
    }
};

export const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// device maps
const iosDeviceMapping = new Map([
    ["320x480", "IPhone 4S, 4, 3GS, 3G, 1st gen"],
    ["320x568", "IPhone 5, SE 1st Gen,5C, 5S"],
    ["375x667", "IPhone SE 2nd Gen, 6, 6S, 7, 8"],
    ["375x812", "IPhone X, XS, 11 Pro, 12 Mini, 13 Mini"],
    ["390x844", "IPhone 13, 13 Pro, 12, 12 Pro"],
    ["414x736", "IPhone 8+"],
    ["414x896", "IPhone 11, XR, XS Max, 11 Pro Max"],
    ["428x926", "IPhone 13 Pro Max, 12 Pro Max"],
    ["476x847", "IPhone 7+, 6+, 6S+"],
    ["744x1133", "IPad Mini 6th Gen"],
    [
      "768x1024",
      "IPad Mini (5th Gen), IPad (1-6th Gen), iPad Pro (1st Gen 9.7), Ipad Mini (1-4), IPad Air(1-2)  ",
    ],
    ["810x1080", "IPad 7-9th Gen"],
    ["820x1180", "iPad Air (4th gen)"],
    ["834x1194", "iPad Pro (3-5th Gen 11)"],
    ["834x1112", "iPad Air (3rd gen), iPad Pro (2nd gen 10.5)"],
    ["1024x1366", "iPad Pro (1-5th Gen 12.9)"],
  ]);
  
  const desktopDeviceMapping = new Map([
    ["Win32", "Windows"],
    ["Linux", "Linux"],
    ["MacIntel", "Mac OS"],
  ]);
  
  // get device name for android
  const getAndroidDeviceName = () => {
    const androidUserAgentString = window.navigator.userAgent.slice(window.navigator.userAgent.indexOf("Android"));
    const androidDeviceName = androidUserAgentString.slice(androidUserAgentString.indexOf("; ") + 1, androidUserAgentString.indexOf(")"));
    if (androidDeviceName) {
      return androidDeviceName.trim().split(" ")[0];
    }
    return "Android";
  };
  
  // get device name for ios
  const getIosDeviceName = () => {
    const screenResolution = `${window.screen.width}x${window.screen.height}`;
    const device = iosDeviceMapping.get(screenResolution);
    if (device) {
      return device;
    }
    return "Iphone";
  };
  
  // get device name for desktop
  const getDesktopDeviceName = () => {
    const platform = navigator?.userAgentData?.platform || navigator?.platform || "unknown";
    const device = desktopDeviceMapping.get(platform) ?? "Unknown";
    return device;
  };
  
  // get device name utility
  export default function getDeviceName() {
    let device = "";
    // check if mobile device
    const isMobileDevice = window.navigator.userAgent
      .toLowerCase()
      .includes("mobi");
  
    if (isMobileDevice) {
      if (window.navigator.userAgent.includes("Android")) {
        device = getAndroidDeviceName();
      } else {
        device = getIosDeviceName();
      }
    } else {
      device = getDesktopDeviceName();
    }
    return device;
  }



export const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
    });
};

export const preloadImages = (imagesArray) => {
    const promises = imagesArray.map((src) => preloadImage(src));
    return Promise.all(promises);
};



export const scrollParentToChild = (parent, child) => {

    // Where is the parent on page
    const parentRect = parent.getBoundingClientRect();
    // What can you see?
    const parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth
    };

    // Where is the child
    const childRect = child.getBoundingClientRect();
    // Is the child viewable?
    const isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);

    // if you can't see the child try to scroll parent
    if (!isViewable) {
        // Should we scroll using top or bottom? Find the smaller ABS adjustment
        const scrollTop = childRect.top - parentRect.top;
        const scrollBot = childRect.bottom - parentRect.bottom;
        if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
            // we're near the top of the list
            parent.scrollTop += scrollTop;
        } else {
            // we're near the bottom of the list
            parent.scrollTop += scrollBot;
        }
    }

}