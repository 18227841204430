import React, { forwardRef, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import Icons from '../components/Icons';
import Avatar from '../assets/Avatar.webp'
import Profile1 from '../assets/logo-symbol.webp'
import Chain from '../assets/tapBot.webp'
import { useGSAP } from '@gsap/react';
import gsap, { TextPlugin } from 'gsap/all';
import Characters from '../components/Characters';
import { clamp, mod } from '../scripts/utils';
import { boostsVal } from '../constants';
import { UserContext } from '../contexts/userContext';
import { CountUp } from 'countup.js';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import WebApp from '@twa-dev/sdk';
import { Player } from '@lottiefiles/react-lottie-player';
const HomePage = ({ name, cgpt, handleValChange, handleButtonEvents, handleProfileView }) => {
    gsap.registerPlugin(TextPlugin);
    const { isConnected, isMobile, energyLevel, level, taps, points, multitapLevel, fullEnergy, turbo, energyLimitLevel, activeCard, dailyRewards, rewardsHandler, userId } = useContext(UserContext);
    const statusCard = useRef();
    const tapsUI = useRef();
    const characterUI = useRef();
    const mainContainer = useRef();
    const tapArea = useRef();
    const { contextSafe } = useGSAP({ dependencies: [isConnected], scope: mainContainer, revertOnUpdate: true });
    const levelBar = useRef();
    const touchable = useRef(true);
    const previousCoin = useRef(0);
    const coinsCount = useRef();
    const coinsCount_tl = useRef();
    const [turboMode, setTurboMode] = useState(false);
    const turboStarted = useRef(false);
    const checkTouchPoints = useRef({
        x: 0,
        y: 0,
    });
    const [dotLottie, setDotLottie] = useState();
    const dotLottieRefCallback = (dotLottie) => {
        setDotLottie(dotLottie);
    };

    const num = useRef(0);
    const tpCache = useRef({
        points: 0,
        x: [],
        y: []
    });

    // useImperativeHandle(ref, () => {
    //     return {
    //         handlePointerEvents,
    //     }
    // });


    useEffect(() => {
        handleValChange("update val", { points: points });
    }, [points])

    useEffect(() => {
        handleValChange("update val", { taps: taps });
    }, [taps])

    useEffect(() => {
        handleValChange("update val", { jobLevel: activeCard.requiredLevel, jobName: activeCard.name, jobProfit: activeCard.profit });
    }, [activeCard])

    useEffect(() => {
        handleValChange("update val", { turbo: turbo });
    }, [turbo])

    useEffect(() => {
        handleValChange("update val", { level: level });
    }, [level])

    useEffect(() => {
        handleValChange("update val", { fullEnergy: fullEnergy });
    }, [fullEnergy])

    useEffect(() => {
        // console.log(energyLevel);

        handleValChange("update val", { energyLevel: energyLevel });
    }, [energyLevel, multitapLevel])

    useEffect(() => {
        handleValChange("update val", { energyLimitLevel: energyLimitLevel });
    }, [energyLimitLevel])

    useEffect(() => {
        handleValChange("update val", { multitapLevel: multitapLevel });
    }, [multitapLevel])



    const debounceTap = (func, timeout = 100) => {
        let timer1 = null;
        return (...args) => {
            if (timer1) {
                clearTimeout(timer1);
                timer1 = null;
            }
            timer1 = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const debouncePointer = (func, timeout = 100) => {
        let timer2 = null;
        return (...args) => {
            if (timer2) {
                clearTimeout(timer2);
                timer2 = null;
            }
            timer2 = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }
    

    const handleTapEvents = contextSafe((type, ev) => {
        ev.preventDefault();
        if (type == "touch start" && touchable.current) {

            const tapAreaX = tapArea.current.getBoundingClientRect().x;
            const tapAreaY = tapArea.current.getBoundingClientRect().y;
            tpCache.current.x = [];
            tpCache.current.y = [];


            if (ev.touches.length > 1) {
                for (let i = 0; i < ev.touches.length; i++) {
                    tpCache.current.x.push(ev.touches.item(i).screenX);
                    tpCache.current.y.push(ev.touches.item(i).screenY);
                    tpCache.current.points = tpCache.current.points + 1;
                }
            } else if (ev.touches.length == 1) {
                tpCache.current.x = [ev.touches[0].screenX];
                tpCache.current.y = [ev.touches[0].screenY];
                tpCache.current.points = ev.touches.length;
            }

            tpCache.current.points = cgpt.energy <= (tpCache.current.points * cgpt.energyCost * cgpt.multitapLevel) ? Math.floor(cgpt.energy / (cgpt.multitapLevel)) : tpCache.current.points;



            if (cgpt.energy > (cgpt.energyCost * cgpt.multitapLevel)) {
                let xbase;
                let ybase;
                let Xd;
                let Yd;
                for (let i = 0; i < tpCache.current.points; i++) {
                    num.current = num.current + 1;
                    num.current = mod(num.current, 20);

                    Xd = tpCache.current.x[i] - (window.innerWidth / 2);
                    Yd = tpCache.current.y[i] - (window.innerHeight / 2);
                    xbase = tpCache.current.x[i] - tapAreaX;
                    ybase = tpCache.current.y[i] - tapAreaY;
                    gsap.to(`.num-${num.current}`, {
                        keyframes: {
                            "0%": { x: xbase, rotateX: 0, scale: 1, y: ybase, autoAlpha: 1 },
                            "80%": { x: xbase + Xd / 5, rotateX: 45, scale: 1, y: ybase / 2, autoAlpha: 1, ease: "sine.out" },
                            "100%": { x: (window.innerWidth / 2), rotateX: 90, scale: 0, y: 0, autoAlpha: 0, ease: "sine.in" },
                            // ease between keyframes
                        },
                        duration: 1,
                        ease: 'sine.inOut'
                    })
                }
                gsap.to('.character', { keyframes: { scale: [1, 1.03, 1] }, duration: 0.25, ease: "sine.in" })
                handleValChange("add coin", { add: tpCache.current.points });
            } else {
                gsap.fromTo(".energy-div-border", { borderColor: "#353539" }, { borderColor: "#ff6666", duration: 0.3, ease: "power2.out", repeat: 1, yoyo: true })
            }


        } else if (type == "touch end") {
            tpCache.current.points = 0;
            tpCache.current.x = [];
            tpCache.current.y = [];
        }

    });

    const counttap = useRef(0);

    const handlePointerEvents = contextSafe((type, object = {}) => {
        const checkObject = Object.keys(object).length === 0 && object.constructor === Object;
        if (!checkObject) object.preventDefault();
        const tapAreaX = tapArea.current.getBoundingClientRect().x;
        const tapAreaY = tapArea.current.getBoundingClientRect().y;
        const statusCardX = statusCard.current.getBoundingClientRect().x;
        const statusCardY = statusCard.current.getBoundingClientRect().height;

        const coinsCountY = coinsCount.current.getBoundingClientRect().y;
        // console.log(statusCardX, coinsCountY);
        const Xd = object.clientX - (window.innerWidth / 2);
        const Yd = object.clientY - (window.innerHeight / 2);

        const thetaX = Math.atan2(Xd, 600) * (180 / Math.PI);
        const thetaY = Math.atan2(Yd, 600) * (180 / Math.PI);
        let xbase;
        let ybase;
        if (!checkObject && type == "tap") {
            if (cgpt.energy > (cgpt.energyCost * cgpt.multitapLevel)) {
                num.current = num.current + 1;
                num.current = mod(num.current, 20);
                xbase = object.clientX - tapAreaX;
                ybase = object.clientY - tapAreaY;
                gsap.to(`.num-${num.current}`, {
                    keyframes: {
                        "0%": { x: xbase, rotateX: 0, scale: 1, y: ybase, autoAlpha: 1 },
                        "45%": { x: xbase + Xd / 5, rotateX: 45, scale: 1, y: ybase / 2, autoAlpha: 1, ease: "sine.out" },
                        "90%": { x: (window.innerWidth / 2), rotateX: 90, scale: 0, y: 0, autoAlpha: 0.5, ease: "sine.out" },
                        "100%": { x: (window.innerWidth / 2), rotateX: 90, scale: 0, y: -50, autoAlpha: 0, ease: "power2.out" },
                        // ease between keyframes
                    },
                    duration: 2,
                    ease: 'sine.inOut'
                })
                gsap.to('.character', { keyframes: { scale: [1, 1.03, 1], skewY: [0, thetaX / 20, 0] }, duration: 0.2, ease: "sine.inOut" })

                handleValChange("add coin", { add: 1 });
            }

        } else if (type == "open boost page") {
            gsap.fromTo(".boost-div-bg", { xPercent: 100 }, { xPercent: 0, duration: 1, ease: "power2.inOut" })
            gsap.fromTo(".boost-div", { xPercent: 100 }, { xPercent: 0, duration: 1.1, ease: "power2.inOut" })
        } else if (type == "back to home") {
            gsap.fromTo(".boost-div", { xPercent: 0 }, { xPercent: 100, duration: 0.5, ease: "power2.inOut", overwrite: true })
            gsap.fromTo(".boost-div-bg", { xPercent: 0 }, {
                xPercent: 100, duration: 0.6, ease: "power2.inOut", overwrite: true
            })
        }
    })


    useGSAP(() => {
        gsap.fromTo(".boost-div", { xPercent: 0 }, { xPercent: 100, duration: 0.02 })
        gsap.fromTo(".boost-div-bg", { xPercent: 0 }, { xPercent: 100, duration: 0.02 })
    }, { dependencies: [], scope: mainContainer, revertOnUpdate: true })

    useLayoutEffect(() => {
        if (WebApp.viewportHeight > 730) {
            const statHeight = statusCard.current.getBoundingClientRect().height;
            const characterY = characterUI.current.getBoundingClientRect().y;
            tapsUI.current.style.height = `${(characterY + 12) - (statHeight + 64)}px`
        }
    }, [WebApp.viewportHeight]);

    useLayoutEffect(() => {
        // console.log(point)
        const intervalId = setInterval(() => {
            if (cgpt.energyCost == 0) {
                if (turboStarted.current == false) {
                    setTurboMode(true);
                    if (dotLottie) {
                        dotLottie.play();
                    }
                    turboStarted.current = true;
                }
            } else if ((cgpt.energyCost == 1)) {
                if (turboStarted.current == true) {
                    setTurboMode(false);

                    if (dotLottie) {
                        dotLottie.stop();
                    }
                    turboStarted.current = false;
                }
            }
        }, 400)
        return () => clearInterval(intervalId);
    }, [dotLottie])

    // useGSAP(() => {
    //     // console.log(point)
    //     // clamp(cgpt.energy, 0, cgpt.energylimit)
    //     const intervalId = setInterval(() => {
    //         gsap.set(".level-num", { text: level, duration: 0.1 });
    //         gsap.set(".level-bar", { width: `${cgpt.levelPercent}%` });
    //         console.log(energyLevel);
    //     }, 100)
    //     return () => clearInterval(intervalId);
    // }, { dependencies: [level], scope: mainContainer, revertOnUpdate: true })


    useGSAP(() => {
        // console.log(point)
        // clamp(cgpt.energy, 0, cgpt.energylimit)
        const intervalId = setInterval(() => {
            // console.log(WebApp.viewportHeight)
            gsap.to(".coins-val", { text: cgpt.value, duration: 0.02 });
            if (WebApp.viewportHeight > 730) gsap.to(".taps-text", { text: cgpt.taps, duration: 0.02 });
            gsap.to(".coinspertap", { text: cgpt.multitapLevel * cgpt.multiplier, duration: 0.02 });
            gsap.to(".coinstolevelup", { text: cgpt.coinstolevelup, duration: 0.02 });
            gsap.to(".profit", { text: cgpt.jobProfit, duration: 0.02 });
            gsap.to(".energy-val", { text: cgpt.energy, duration: 0.02 });
            gsap.to(".energy-limit-val", { text: cgpt.energylimit, duration: 0.02 });
            gsap.to(".levelname", { text: cgpt.jobName, duration: 0.02 });
            gsap.to(".level-num", { text: cgpt.level, duration: 0.02 });
            gsap.to(".num-text", { text: cgpt.multitapLevel * cgpt.multiplier, duration: 0.02 });
            gsap.to(".level-bar", { width: `${cgpt.levelPercent}%`, duration: 0.05 });


            gsap.to(".turbo-val", { text: cgpt.turboCount, duration: 0.02 });
            gsap.to(".next-level-multitap", { text: cgpt.multitapLevel, duration: 0.02 });
            gsap.to(".next-level-energyLimit", { text: cgpt.energyLimitLevel, duration: 0.02 });
            gsap.to(".fullenergy-val", { text: cgpt.fullEnergyCount, duration: 0.02 });
            gsap.to(".multitap-price", { text: (cgpt.multitapLevel - 1) < boostsVal.multitap.length ? boostsVal.multitap[cgpt.multitapLevel - 1] : "Fully Upgraded", duration: 0.02 });
            gsap.to(".energyLimit-price", { text: (cgpt.energyLimitLevel - 1) < boostsVal.energyLimit.length ? boostsVal.energyLimit[cgpt.energyLimitLevel - 1] : "Fully Upgraded", duration: 0.02 });

            if (cgpt.turboCount > 0) {
                gsap.set(".turbo-btn", { pointerEvents: "auto", opacity: 1 });
            } else {
                gsap.set(".turbo-btn", { pointerEvents: "none", opacity: 0.5 });
            }

            // if (cgpt.energy >= (cgpt.energyCost * cgpt.multitapLevel)) {
            //     touchable.current = true;
            //     tapArea.current.style.pointerEvents = "auto";
            // } else {
            //     touchable.current = false;
            //     tapArea.current.style.pointerEvents = "none";
            // }

            if (cgpt.fullEnergyCount > 0) {
                gsap.set(".full-energy-btn", { pointerEvents: "auto", opacity: 1 });
            } else {
                gsap.set(".full-energy-btn", { pointerEvents: "none", opacity: 0.5 });
            }

            if (cgpt.value >= boostsVal.multitap[cgpt.multitapLevel - 1] && (cgpt.multitapLevel - 1 < boostsVal.multitap.length)) {
                gsap.set(".multitap-btn", { pointerEvents: "auto", opacity: 1 });
            } else {
                gsap.set(".multitap-btn", { pointerEvents: "none", opacity: 0.5 });
            }

            if (cgpt.value >= boostsVal.energyLimit[cgpt.energyLimitLevel - 1]) {
                gsap.set(".energyLimit-btn", { pointerEvents: "auto", opacity: 1 });
            } else {
                gsap.set(".energyLimit-btn", { pointerEvents: "none", opacity: 0.5 });
            }

        }, 50)
        return () => clearInterval(intervalId);
    }, { dependencies: [], scope: mainContainer, revertOnUpdate: true })


    const [dailyRewardTimeLeft, setDailyRewardTimeLeft] = useState("");

    const calculateTimeLeft = (previousDateString) => {
        const now = new Date();
        const previousDate = new Date(previousDateString);
        const target = new Date(previousDate);
        target.setDate(previousDate.getDate() + 1);
        target.setUTCHours(0, 0, 0, 0);

        const diff = clamp(target.getTime() - now.getTime(), 0, Infinity);

        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        const paddedHours = hours.toString().padStart(2, '0');
        const paddedMinutes = minutes.toString().padStart(2, '0');
        const paddedSeconds = seconds.toString().padStart(2, '0');

        return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    };

    useEffect(() => {
        const updateCountdowns = () => {
            if (dailyRewards.length > 0) {

                setDailyRewardTimeLeft(calculateTimeLeft(dailyRewards[dailyRewards.length - 1].date));
                if (dailyRewards[dailyRewards.length - 1].date === '00:00:00') {
                    rewardsHandler("Daily Rewards");
                }
            }
        };
        updateCountdowns();
        const interval = setInterval(updateCountdowns, 1000); // Update every second

        return () => clearInterval(interval);
    }, [dailyRewards]);




    return (
        <div ref={mainContainer} className='w-full h-dvh flex flex-col gap-4 px-4 py-4 relative z-[1] select-none'>
            <div className="absolute touch-none w-full h-full flex flex-col justify-end bottom-[12rem] left-0 z-[1] pointer-events-none">
                <div className="w-full aspect-square max-h-[20rem] relative z-[3]">
                    <Player
                        className="size-full"
                        src="/cgptTap_turbo.json"
                        lottieRef={dotLottieRefCallback}
                    />
                </div>
            </div>
            <div ref={tapArea} onClick={debouncePointer((e) => { if (import.meta.env.VITE_NETLIFY_ALLOW && import.meta.env.VITE_NETLIFY_ALLOW === "true") handlePointerEvents("tap", e) })} onTouchStart={debounceTap((e) => handleTapEvents("touch start", e))} onTouchEnd={(e) => handleTapEvents("touch end", e)} className="tap-area absolute touch-none w-full h-full flex flex-col justify-end bottom-[10rem] left-0 px-5 z-[2]">
                {WebApp.viewportHeight > 730 && <div ref={tapsUI} className="w-full h-[30vh] flex justify-center items-center relative">
                    <div className="p-2 border-light rounded-full relative bg-[#202023] bg-opacity-80 ">
                        <div className={`size-full ${turboMode ? 'border-gradient-3' : 'border-light'} relative flex justify-between px-4 py-3 items-center gap-2 rounded-full bg-[#202023] `}>
                            <h1 className="text-white text-sm font-normal  leading-tight"><span className='taps-text'>{cgpt.taps}</span></h1>
                            <h1 className="text-white text-sm font-normal grow shrink basis-0  leading-tight">Taps</h1>

                            {turboMode && <div className="w-[25px] aspect-square">
                                <Icons name="Lightning Outline" className="size-full" />
                            </div>
                            }
                        </div>
                    </div>
                </div>
                }
                <div ref={characterUI} className='w-full -mt-[2rem] aspect-square max-h-[20rem] character relative z-[3]'>
                    <Characters level={cgpt.jobLevel} className="size-full relative" />
                </div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-0`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-1`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-2`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-3`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-4`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-5`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-6`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-7`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-8`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-9`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-10`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-11`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-12`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-13`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-14`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-15`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-16`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-17`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-18`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                <div className={`absolute opacity-0 top-0 left-0 pointer-events-none font-['Violet_Sans'] text-white font-bold text-2xl z-[10] num-19`}>+<span className="num-text">{cgpt.multitapLevel * cgpt.multiplier}</span></div>

            </div>
            <div className="w-full h-[2rem] flex justify-between z-[8]">
                <div className="h-full aspect-square">
                    <Icons name="Logo" className="size-full" />
                </div>
                <div onClick={(e) => handleProfileView("open profile page", e)} className='flex h-full items-center justify-end gap-2'>
                    <div className="h-full aspect-square">
                        <Icons name="Photo Frame" className="size-full" imagesrc={Profile1} />
                    </div>
                    <h1 className="text-white font-['Roboto_Mono'] uppercase text-[12px]">{name}</h1>
                </div>
            </div>

            <div ref={statusCard} className="w-full flex-col justify-start items-start gap-2 inline-flex relative blur-bg z-[1]">
                <div className="bg-card-1 absolute top-0 left-0 size-full flex justify-center items-center z-[1] ">
                    <div className="size-full relative bg-card-2 opacity-50"></div>
                </div>
                <div className="w-full  flex-col justify-start items-start flex px-3 py-3 relative z-[2]">
                    <div className="relative w-full flex-col justify-start items-start gap-2 flex font-['Violet_Sans']">
                        <div className="w-full flex-col justify-center items-start gap-2.5 flex">
                            <div className="px-2 py-1 bg-[#f8cf3f]/10 rounded-sm justify-start items-start gap-2.5 inline-flex">
                                <div className="text-center text-[#f8cf3f] text-[13px] font-normal ">Total Balance</div>
                            </div>
                        </div>
                        <div className="relative z-[0] w-full text-[#efefe5] text-[30px] font-normal  leading-9"><span ref={coinsCount} className='coins-val'>{cgpt.value}</span></div>
                        <div className="w-full flex-col justify-start items-start gap-2 flex">
                            <div className="w-full justify-between items-center gap-4 inline-flex">
                                <div className="grow shrink basis-0 text-white text-sm font-normal  leading-tight">Earn per tap</div>
                                <div className="justify-start items-center gap-2.5 flex">
                                    <div className="text-right text-[#efefe5]/60 text-sm font-normal  leading-tight"><span className='coinspertap'>{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                                </div>
                            </div>
                            <div className="w-full justify-between items-center gap-4 inline-flex">
                                <div className="grow shrink basis-0 text-white text-sm font-normal  leading-tight">Coins to level up</div>
                                <div className="justify-start items-center gap-2.5 flex">
                                    <div className="text-right text-[#efefe5]/60 text-sm font-normal  leading-tight"><span className='coinstolevelup'>{cgpt.coinstolevelup}</span></div>
                                </div>
                            </div>
                            <div className="w-full justify-between items-center gap-4 inline-flex">
                                <div className="grow shrink basis-0 text-white text-sm font-normal  leading-tight">Profit</div>
                                <div className="justify-start items-center gap-2.5 flex">
                                    <div className="text-right text-[#efefe5]/60 text-sm font-normal  leading-tight"><span className='profit'>{cgpt.jobProfit}</span> per hr</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="pointer-events-none absolute h-full bottom-0 left-0 w-full px-4 pt-4 pb-[6rem] flex flex-col gap-3 items-start opacity-100 justify-end z-[5]">
                <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                    <div className="w-full justify-between items-center gap-4 inline-flex">
                        <p className="grow shrink basis-0 text-[#efefe5] text-sm font-normal  leading-tight"><span className='levelname'>{cgpt.jobName}</span></p>
                        <div className="justify-start items-center gap-2.5 flex">
                            <div className="text-right text-[#efefe5]/60 text-sm font-normal font-['Violet_Sans'] leading-tight">Level <span className='level-num'>{cgpt.level}</span>/10</div>
                        </div>
                    </div>
                    <div ref={levelBar} className="w-full h-6 relative flex justify-start items-center ">
                        <div className="w-full h-3 relative flex justify-start items-center bg-[#353539]">
                            <div className="w-[0%] h-full bg-gradient-cgpt level-bar relative" />
                        </div>

                    </div>
                </div>
                <div className="w-full justify-start items-start gap-2.5 flex">
                    <div onClick={(e) => handleButtonEvents("open daily page", e)} className={` ${dailyRewards.length > 0 && dailyRewards[dailyRewards.length - 1].claimed == 0 ? ' border-gradient' : ' border-light'} pointer-events-auto grow shrink basis-0 h-[2.1rem] relative flex items-center justify-center gap-1 bg-[#111115] `}>
                        <div className={`w-[1rem] aspect-square ${dailyRewards.length > 0 && dailyRewards[dailyRewards.length - 1].claimed == 1 ? 'block' : 'hidden'}`}>
                            <Icons name="Clock" className="size-full" />
                        </div>
                        <h1 className="text-white text-xs leading-[1]">{dailyRewards[dailyRewards.length - 1].claimed == 0 ? 'Daily Rewards' : dailyRewardTimeLeft}</h1>
                    </div>
                    <div className="energy-div-border grow shrink basis-0 h-[2.1rem] relative flex items-center justify-center gap-1 bg-[#111115] border-light">
                        <div className="w-[1rem] aspect-square">
                            <Icons name="Up Circle" className="size-full" />
                        </div>
                        <h1 className="text-white text-xs leading-[1]"><span className='energy-val'>{cgpt.energy}</span> / <span className='energy-limit-val'>{cgpt.energylimit}</span></h1>
                    </div>
                    <div onClick={(e) => handlePointerEvents("open boost page", e)} className="pointer-events-auto grow shrink basis-0 h-[2.1rem] relative flex items-center justify-center gap-1 bg-[#111115] border-gradient">
                        <div className="w-[1rem] aspect-square">
                            <Icons name="Lightning" className="size-full" />
                        </div>
                        <h1 className="text-white text-xs leading-[1]">Boost</h1>
                    </div>
                </div>
            </div>
            <div className="boost-div-bg absolute top-0 left-0 size-full gradient-t-to-b z-[5] flex justify-end"></div>
            <div className="boost-div absolute top-0 left-0 size-full bg-colour flex flex-col justify-start px-3 z-[6] gap-6 pt-[4rem]">
                <div className="status-card w-full flex-col justify-start items-start gap-2 inline-flex relative blur-bg z-[8]">
                    <div className="bg-card-1 absolute top-0 left-0 size-full flex justify-center items-center z-[1] ">
                        <div className="size-full relative bg-card-2 opacity-50"></div>
                    </div>
                    <div className="w-full  flex-col justify-start items-start flex px-3 py-3 relative z-[2]">
                        <div className="w-full flex-col justify-start items-start gap-2 flex font-['Violet_Sans']">
                            <div className="w-full flex-col justify-center items-start gap-2.5 flex">
                                <div className="px-2 py-1 bg-[#f8cf3f]/10 rounded-sm justify-start items-start gap-2.5 inline-flex">
                                    <div className="text-center text-[#f8cf3f] text-[13px] font-normal ">Total Balance</div>
                                </div>
                            </div>
                            <div className="w-full text-[#efefe5] text-[30px] font-normal  leading-9"><span className='coins-val'>{cgpt.value}</span></div>
                            <div className="w-full flex-col justify-start items-start gap-2 flex">
                                <div className="w-full justify-between items-center gap-4 inline-flex">
                                    <div className="grow shrink basis-0 text-white text-sm font-normal  leading-tight">Earn per tap</div>
                                    <div className="justify-start items-center gap-2.5 flex">
                                        <div className="text-right text-[#efefe5]/60 text-sm font-normal  leading-tight"><span className='coinspertap'>{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                                    </div>
                                </div>
                                <div className="w-full justify-between items-center gap-4 inline-flex">
                                    <div className="grow shrink basis-0 text-white text-sm font-normal  leading-tight">Coins to level up</div>
                                    <div className="justify-start items-center gap-2.5 flex">
                                        <div className="text-right text-[#efefe5]/60 text-sm font-normal  leading-tight"><span className='coinstolevelup'>{cgpt.coinstoleveluo}</span></div>
                                    </div>
                                </div>
                                <div className="w-full justify-between items-center gap-4 inline-flex">
                                    <div className="grow shrink basis-0 text-white text-sm font-normal  leading-tight">Profit</div>
                                    <div className="justify-start items-center gap-2.5 flex">
                                        <div className="text-right text-[#efefe5]/60 text-sm font-normal  leading-tight"><span className='profit'>{cgpt.jobProfit}</span> per hr</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='flex flex-col justify-end relative w-full gap-2'>
                    <div onClick={(e) => handlePointerEvents("back to home", e)} className="w-full flex">
                        <div className="w-[24px] aspect-square">
                            <Icons name="Arrow Left" className="size-full" />
                        </div>
                    </div>
                    <div className='w-full'>
                        <h1 className="text-white text-sm font-['Violet_Sans']">Free Daily Boosters</h1>
                    </div>
                    <div className="w-full grid grid-cols-2 justify-start items-start gap-2.5">
                        <div onClick={(e) => { handleButtonEvents("refill energy", e); handlePointerEvents("back to home", e) }} className=" full-energy-btn btn-inactive relative flex items-center justify-between px-3 py-3 gap-3 bg-[#111115] border-light">
                            <div className="flex flex-col gap-1">
                                <h1 className="text-white text-sm">Full Energy</h1>
                                <h1 className="text-[#EFEFE5] text-xs"><span className='fullenergy-val'>{cgpt.fullEnergyCount}</span>/3 Available</h1>
                            </div>
                            <div className="w-[40px] aspect-square">
                                <Icons name="Full Battery" className="size-full" />
                            </div>

                        </div>
                        <div onClick={(e) => { handleButtonEvents("turbo", e); handlePointerEvents("back to home", e) }} className="turbo-btn btn-inactive  relative flex items-center justify-between px-3 py-3 gap-3 bg-[#111115] border-light">
                            <div className="flex flex-col gap-1">
                                <h1 className="text-white text-sm">Turbo</h1>
                                <h1 className="text-[#EFEFE5] text-xs"><span className='turbo-val'>{cgpt.turboCount}</span>/3 Available</h1>
                            </div>
                            <div className="w-[40px] aspect-square">
                                <Icons name="Lightning Outline" className="size-full" />
                            </div>
                        </div>
                    </div>
                    <div className='w-full'>
                        <h1 className="text-white text-sm font-['Violet_Sans']">Boosts</h1>
                    </div>
                    <div className='w-full grid grid-cols-2 justify-start items-start gap-2.5'>
                        <div onClick={(e) => { handleButtonEvents("multitap", e); handlePointerEvents("back to home", e) }} className="multitap-btn btn-inactive  relative flex items-center justify-between px-3 py-3 gap-3 bg-[#111115] border-light">
                            <div className="flex flex-col gap-1">
                                <h1 className="text-white text-sm">Multitap</h1>
                                <h1 className="text-[#EFEFE5] text-xs"><span className="multitap-price text-[#27AE60]">{boostsVal.multitap[cgpt.multitapLevel - 1]}</span> Lvl <span className="next-level-multitap">{cgpt.multitapLevel}</span></h1>
                            </div>
                            <div className="w-[40px] aspect-square">
                                <Icons name="Multitap" className="size-full" />
                            </div>

                        </div>
                        <div onClick={(e) => { handleButtonEvents("change energy limit", e); handlePointerEvents("back to home", e) }} className="energyLimit-btn btn-inactive  relative flex items-center justify-between px-3 py-3 gap-3 bg-[#111115] border-light ">
                            <div className="flex flex-col gap-1">
                                <h1 className="text-white text-sm">Energy Limit</h1>
                                <h1 className="text-[#EFEFE5] text-xs"><span className="energyLimit-price text-[#27AE60]">{boostsVal.energyLimit[cgpt.energyLimitLevel - 1]}</span> Lvl <span className="next-level-energyLimit">{cgpt.energyLimitLevel}</span></h1>
                            </div>
                            <div className="w-[40px] aspect-square">
                                <Icons name="Lightning Battery" className="size-full" />
                            </div>
                        </div>

                    </div>
                </div>



                <button className="relative ">

                    <div className="absolute top-0 left-0 size-full btn-cgpt-rounded"><div className="btn-cgpt-1 relative size-full "></div></div>
                    <div className="absolute top-0 left-0 size-full btn-cgpt-rounded-2"><div className="btn-cgpt-2 relative size-full "></div></div>
                    {/* <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <filter id="flt_tag">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                            </filter>
                            <filter id="flt_tag_2">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                            </filter>
                        </defs>
                    </svg> */}
                    <div onClick={(e) => handleButtonEvents("open boost page", e)} className="w-full relative py-4 px-6 flex justify-center items-center gap-2">
                        <div className="w-[16px] aspect-square">
                            <Icons name="Lightning" className="size-full" />
                        </div>
                        <p className="text-white text-sm font-['Roboto_Mono']">How a boost works</p>
                    </div>
                </button>
            </div>

        </div>

    );
}

export default HomePage;
