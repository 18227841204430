import React from "react";
import Robot0 from "../assets/cards/00-default.webp";
import Robot1 from "../assets/cards/01.webp";
import Robot2 from "../assets/cards/02.webp";
import Robot3 from "../assets/cards/03.webp";
import Robot4 from "../assets/cards/04.webp";
import Robot5 from "../assets/cards/05.webp";
import Robot6 from "../assets/cards/06.webp";
import Robot7 from "../assets/cards/07.webp";
import Robot8 from "../assets/cards/08.webp";
import Robot9 from "../assets/cards/09.webp";
import Robot10 from "../assets/cards/10.webp";

const Cards = ({ level, className }) => {
    return (
        <div className={className}>
            {(() => {
                switch (level.toString()) {
                    case "0":
                        return (

                            <img src={Robot0} className='size-full object-contain object-bottom' />

                        )
                    case "1":
                        return (

                            <img src={Robot1} className='size-full object-contain object-bottom' />

                        )
                    case "2":
                        return (

                            <img src={Robot2} className='size-full object-contain object-bottom' />

                        )

                    case "3":
                        return (

                            <img src={Robot3} className='size-full object-contain object-bottom' />

                        )
                    case "4":
                        return (

                            <img src={Robot4} className='size-full object-contain object-bottom' />


                        )
                    case "5":
                        return (

                            <img src={Robot5} className='size-full object-contain object-bottom' />

                        )
                    case "6":
                        return (

                            <img src={Robot6} className='size-full object-contain object-bottom' />

                        )
                    case "7":
                        return (

                            <img src={Robot7} className='size-full object-contain object-bottom' />

                        )
                    case "8":
                        return (

                            <img src={Robot8} className='size-full object-contain object-bottom' />

                        )
                    case "9":
                        return (

                            <img src={Robot9} className='size-full object-contain object-bottom' />


                        )
                    case "10":
                        return (

                            <img src={Robot10} className='size-full object-contain object-bottom' />


                        )

                }
            })()}

        </div>
    );
};

export default Cards;
