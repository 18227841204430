import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import CloseButton from '../components/CloseButton';
import telegramIcon from '../assets/cgpt-telegram.webp'
import coinIcon from '../assets/cgpt-coins.webp'
import { useGSAP } from '@gsap/react';
import gsap from 'gsap/all';
import { UserContext } from '../contexts/userContext';

const QuestModal = ({ task, handleButtonEvents, rerender, handleValChange }) => {
    const mainContainer = useRef();
    const scrambleModalTitle = useRef();
    const codeInput = useRef();
    const warningMessage = useRef();
    const missionOpened = useRef(false);
    const checkedOnce = useRef(false);
    const errorMessage = useRef(false);
    const tasksDiv = useRef();
    const { userId } = useContext(UserContext);
    const intervalId = useRef();






    const isValidLink = (taskLink) => {
        return (taskLink.startsWith("https://"))
    }

    useLayoutEffect(() => {
        if (intervalId.current) clearInterval(intervalId.current);
        const warningMessage1 = document.getElementById(`warning-${task.id}`);
        const buttonCheck = document.getElementById(`button-${task.id}`);
        warningMessage1.style.display = "none";
        warningMessage1.innerText = "";
        buttonCheck.innerText = "GO";
        buttonCheck.style.opacity = 1;
        buttonCheck.style.pointerEvents = "auto";
        checkedOnce.current = false;
    }, [rerender])




    const { contextSafe } = useGSAP({ scope: mainContainer, revertOnUpdate: true });
    const handlePointerEvents = contextSafe((type, object, taskObject = {}) => {
        object.preventDefault();
        if (type == "close task page") {
            handleButtonEvents("close task page", object);
        } else if (type == "go button") {
            if (object.target.innerText == "GO") {

                window.open(
                    isValidLink(taskObject.link) ? taskObject.link : `https://${taskObject.link}`,
                    '_blank' // <- This is what makes it open in a new window.
                );
                object.target.innerText = "CHECK"
            } else if (object.target.innerText == "CHECK") {
                const warningMessage = document.getElementById(`warning-${taskObject.id}`);

                if (taskObject.platform == "telegram") {
                    if (taskObject.status == "Pending") {
                        fetch(`${import.meta.env.VITE_API_BASE_URL}/tasks/${taskObject.id}`, {
                            headers: {
                                Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                            }
                        })
                            .then((res) => {
                                res.json().then((res) => {
                                    // console.log(res);
                                    if (res.task.status == "Pending") {
                                        object.target.style.opacity = 0.5;
                                        object.target.style.pointerEvents = "none";
                                        warningMessage.style.display = "block";
                                        warningMessage.innerText = "Activity not confirmed. Are you certain you completed this task? Click here to check.";
                                        let countdown = 30;
                                        intervalId.current = setInterval(() => {
                                            countdown--;
                                            if (countdown > 0) {
                                                object.target.innerText = `${countdown}s`
                                            } else {
                                                object.target.innerText = "CHECK";
                                                checkedOnce.current = true;
                                                object.target.style.opacity = 1;
                                                object.target.style.pointerEvents = "auto";
                                                warningMessage.style.display = "none";
                                                warningMessage.innerText = "";
                                                clearInterval(intervalId.current);
                                            }
                                        }, 1000)
                                    } else if (res.task.status == "Claimable") {
                                        object.target.innerText = "Done";
                                        object.target.style.opacity = 0.5;
                                        object.target.style.pointerEvents = "none";
                                        const claimButton = document.getElementById("claim-button");
                                        claimButton.style.pointerEvents = "auto";
                                        claimButton.style.opacity = 1;
                                    }
                                })
                            }).catch((e) => {
                                // console.log("Error")
                            })
                    } else {
                        object.target.innerText = "Done";
                        object.target.style.opacity = 0.5;
                        object.target.style.pointerEvents = "none";
                        const claimButton = document.getElementById("claim-button");
                        claimButton.style.pointerEvents = "auto";
                        claimButton.style.opacity = 1;
                    }
                } else {
                    if (!checkedOnce.current) {
                        object.target.style.opacity = 0.5;
                        object.target.style.pointerEvents = "none";
                        warningMessage.style.display = "block";
                        warningMessage.innerText = "Activity not confirmed. Are you certain you completed this task? Click here to check.";
                        let countdown = 30;
                        intervalId.current = setInterval(() => {
                            countdown--;
                            if (countdown > 0) {
                                object.target.innerText = `${countdown}s`
                            } else {
                                object.target.innerText = "CHECK";
                                checkedOnce.current = true;
                                object.target.style.opacity = 1;
                                object.target.style.pointerEvents = "auto";
                                warningMessage.style.display = "none";
                                warningMessage.innerText = "";
                                clearInterval(intervalId.current);
                            }
                        }, 1000)
                    } else {
                        object.target.innerText = "Done";
                        object.target.style.opacity = 0.5;
                        object.target.style.pointerEvents = "none";
                        const claimButton = document.getElementById("claim-button");
                        claimButton.style.pointerEvents = "auto";
                        claimButton.style.opacity = 1;
                        




                    }
                }


            }



        } else if (type == "claim button") {
            // console.log("Claiming");
            handleButtonEvents("success claim", object);

        }

    })




    return (



        <div ref={mainContainer} className={`relative w-full ${rerender ? "" : ""}`}>
            <div className="bg-modal-1 absolute top-0 left-0 size-full flex justify-center items-center z-[1] ">
                <div className="size-full relative bg-modal-2 opacity-100"></div>
            </div>
            <div className=" relative w-full flex flex-col gap-6 justify-start items-center p-6 z-[2]">

                <div className="text-box w-full grow flex flex-col items-start justify-center gap-1.5">
                    <div className="w-full flex-col justify-center items-start  flex">
                        <div className="px-2 py-1 bg-[#27AE60]/10 rounded-sm justify-start items-start inline-flex">
                            <div className="text-center text-[#27AE60] text-[13px] font-normal ">Quests</div>
                        </div>
                    </div>

                    <div className="modal-pic w-full h-[6rem] relative mt-2 flex justify-center">
                        <img src={task.platform == "telegram" ? telegramIcon : coinIcon} className='object-contain object-center size-full ' />
                    </div>
                    <h1 ref={scrambleModalTitle} className="w-full text-[1.5rem] text-[#fff] font-['Violet_Sans'] text-center tracking-[-0.5px]">
                        {task.title}
                    </h1>
                    <h1 className=" w-full font-light text-[#b2b2b2] text-xs text-center">
                        {task.description ? task.description : `Perform the task and get ${task.rewards} in return.`}
                    </h1>
                </div>
                <div className={` flex w-full px-3 border-light bg-[#1A1A1F] py-3 justify-center items-center gap-2.5 `}>
                    <div className="grow shrink basis-0 flex-col justify-center items-start gap-[1px] inline-flex">
                        <div className="w-full text-white text-[12px] fontlight font-['Roboto_Mono'] leading-none">Complete the task</div>
                        <a id={`warning-${task.id}`} href={isValidLink(task.link) ? task.link : `https://${task.link}`} target="_blank" rel="noopener noreferrer" className="external-link warning-message hidden mt-2 text-[12px] text-red-500 leading-[1.1] font-['Violet_Sans']"></a>
                    </div>
                    <button id={`button-${task.id}`} onClick={(e) => handlePointerEvents("go button", e, task)} className={`${task.status == "Claimable" || task.status == "Claimed" ? "pointer-events-none opacity-50" : "pointer-events-auto opacity-100"} text-white text-xs uppercase font-medium font-['Roboto_Mono'] leading-[13.20px] change-button px-4 py-2 w-[15vw] bg-accent-2 justify-center items-center gap-2.5 flex rounded-[5px] border-light perform-task-button`}>{task.status == "Claimable" ? "Done" : "Go"}</button>
                </div>


                <button onClick={(e) => handlePointerEvents("claim button", e, task)} id="claim-button" className={`relative w-full ${task.status == "Claimable" ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-50'}`}>

                    <div className="absolute top-0 left-0 size-full btn-cgpt-rounded"><div className="btn-modal-cgpt-short relative size-full "></div></div>
                    <div className="absolute top-0 left-0 size-full btn-cgpt-rounded-2"><div className="btn-modal-cgpt-2 relative size-full "></div></div>
                    {/* <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <filter className="flt_tag">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                            </filter>
                            <filter id="flt_tag_2">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                            </filter>
                        </defs>
                    </svg> */}
                    <div className="w-full relative py-4 px-6 flex justify-center items-center gap-2">
                        <p className="text-white text-sm font-['Roboto_Mono']">{task.status != "Claimed" ? `Claim ${task.rewards} coins` : 'Already Claimed'}</p>
                    </div>
                </button>
                <div onClick={(e) => handleButtonEvents("close task page", e)} className="close-button-quest absolute pointer-events-auto right-[1.5rem] top-[1.5rem] w-[1.5rem] h-[1.5rem] z-[5]">
                    <CloseButton />
                </div>

            </div>

        </div>

    );
}

export default QuestModal;
