
export const ENERGY_LEVEL_MULTIPLIER = 500
export const maxSuperSpeed = 3
export const maxFullEnergy = 3
export const tapBotPrice = 200000
export const refRewardBasic = 1000
export const refRewardPremium = 2500

export const boostsVal = {
    multitap: [
        200,
        500,
        1000,
        2000,
        4000,
        8000,
        16000,
        25000,
        50000,
        100000,
        200000,
        300000,
        400000,
        500000,
        600000,
    ],
    energyLimit: [
        200,
        500,
        1000,
        2000,
        4000,
        8000,
        16000,
        25000,
        50000,
        100000,
        200000,
        300000,
        400000,
        500000,
        600000
    ],
    rechargeSpeed: [
        2000,
        10000,
        100000,
        250000,
        500000
    ]
};



export const invitesVal = [
    {
        referrals: 1,
        rewards: 2500
    },
    {
        referrals: 3,
        rewards: 50000
    },
    {
        referrals: 10,
        rewards: 200000
    },
    {
        referrals: 25,
        rewards: 250000
    },
    {
        referrals: 50,
        rewards: 300000
    },
    {
        referrals: 100,
        rewards: 500000
    },
    {
        referrals: 500,
        rewards: 2000000
    },
]
export const levelsVal =[
    {
        level: 0,
        min: 0,
        max: 500
    },
    {
        level: 1,
        min: 500,
        max: 2500,
    },
    {
        level: 2,
        min: 2500,
        max: 7500,
    },
    {
        level: 3,
        min: 7500,
        max: 15000,
    },
    {
        level: 4,
        min: 15000,
        max: 40000,
    },
    {
        level: 5,
        min: 40000,
        max: 80000,
    },
    {
        level: 6,
        min: 80000,
        max: 160000,
    },
    {
        level: 7,
        min: 160000,
        max: 400000,
    },
    {
        level: 8,
        min: 400000,
        max: 800000,
    },
    {
        level: 9,
        min: 800000,
        max: 1600000,
    },
    {
        level: 10,
        min: 1600000,
        max: Infinity,
    },
]


export const sampleUsers = [
    {
        username: "sampleuser01",
        level: 3,
        points: 12300
    },
    {
        username: "sampleuser08",
        level: 3,
        points: 10600
    },
    {
        username: "sampleuser_4",
        level: 3,
        points: 9600
    },
    {
        username: "sampleuser05",
        level: 3,
        points: 7600
    },
    {
        username: "sampleuser04",
        level: 2,
        points: 2300
    },
    {
        username: "sampleuser07",
        level: 0,
        points: 450
    },
    {
        username: "sampleuser08",
        level: 0,
        points: 0
    },
    {
        username: "sampleuser09",
        level: 0,
        points: 0
    },
    {
        username: "sampleuser10",
        level: 0,
        points: 0
    },
]

export const jobsVal = [
    {
        name: "Unemployed AI",
        description: "You're an AI without a purpose, searching for your first opportunity to break into the tech world. The journey starts here.",
        requiredLevel: 0,
        profit: 0,
        cost: 0
    },
    {
        name: "Junior Robot Assistant",
        description: "Just getting started, you are a junior assistant learning the ropes of AI development.",
        requiredLevel: 1,
        profit: 100,
        cost: 1440
    },
    {
        name: "AI Research Intern",
        description: "You've proven your skills as a junior assistant and have been promoted to an AI research intern.",
        requiredLevel: 2,
        profit: 200,
        cost: 3360
    },
    {
        name: "Data Analyst Bot",
        description: "With your growing knowledge, you are now responsible for analyzing vast datasets to train AI models.",
        requiredLevel: 3,
        profit: 500,
        cost: 9600,
    },
    {
        name: "AI Developer",
        description: "You've become proficient in coding and developing AI algorithms, making significant contributions to projects.",
        requiredLevel: 4,
        profit: 1000,
        cost: 21600,
    },
    {
        name: "Project Manager",
        description: "Now a project manager, you lead a team of AI developers to tackle more complex tasks.",
        requiredLevel: 5,
        profit: 2000,
        cost: 48000
    },
    {
        name: "Senior AI Engineer",
        description: "As a senior AI engineer, you innovate and oversee the integration of cutting-edge AI technologies.",
        requiredLevel: 6,
        profit: 4000,
        cost: 105600
    },
    {
        name: "Department Head",
        description: "You now head an entire department, guiding multiple teams to achieve AI breakthroughs.",
        requiredLevel: 7,
        profit: 8000,
        cost: 230400
    },
    {
        name: "VP of AI",
        description: "As the Vice President of AI, you strategize and implement the company's AI initiatives on a larger scale.",
        requiredLevel: 8,
        profit: 16000,
        cost: 499200
    },
    {
        name: "Chief Technology Officer",
        description: "You've reached the executive level, shaping the technological direction and overseeing all AI operations.",
        requiredLevel: 9,
        profit: 25000,
        cost: 840000
    },
    {
        name: "AI Overlord",
        description: "The pinnacle of your journey, you are now the founder and CEO of the most advanced AI company in the world, an AI Overlord.",
        requiredLevel: 10,
        profit: 50000,
        cost: 1800000
    },
];
