import React from "react";

const CloseButton = () => {
  return (
    <div className="w-full h-full">
      <svg width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fillRule="evenodd" clipRule="evenodd" d="M10.5859 12.0001L7.29297 8.70718L8.70718 7.29297L12.0001 10.5859L15.293 7.29297L16.7072 8.70718L13.4143 12.0001L16.7072 15.293L15.293 16.7072L12.0001 13.4143L8.70718 16.7072L7.29297 15.293L10.5859 12.0001Z" fill="white" />
          <path fillRule="evenodd" clipRule="evenodd" d="M15 0H20.3615C20.9929 0 21.601 0.238971 22.0635 0.6689L23.3085 1.82614C23.8173 2.29912 24.1064 2.96252 24.1064 3.65724V8.5H23.1064V3.65724C23.1064 3.2404 22.933 2.84237 22.6277 2.55858L21.3827 1.40134C21.1052 1.14338 20.7403 1 20.3615 1H15V0Z" fill="#EFEFE5" fillOpacity="0.3" />
          <path fillRule="evenodd" clipRule="evenodd" d="M9 24H3.63854C3.00706 24 2.39898 23.761 1.93646 23.3311L0.691507 22.1739C0.182666 21.7009 -0.106414 21.0375 -0.106414 20.3428V15.5H0.893586V20.3428C0.893586 20.7596 1.06703 21.1576 1.37234 21.4414L2.61729 22.5987C2.89481 22.8566 3.25966 23 3.63854 23H9V24Z" fill="#EFEFE5" fillOpacity="0.3" />
        </g>
      </svg>

    </div>
  );
};

export default CloseButton;
