import React, { useContext, useEffect, useRef } from 'react';

import Profile1 from '../assets/logo-symbol.webp'
import Icons from '../components/Icons';
import Avatar from '../assets/Avatar.webp'
import Coin from '../assets/coin.png'
import Box from '../assets/subscribe.png'
import { useGSAP } from '@gsap/react';
import gsap from 'gsap/all';
import { UserContext } from '../contexts/userContext';
import { formatNumber } from '../scripts/utils';
import { refRewardBasic, refRewardPremium } from '../constants';

const FriendsPage = ({ name, handleButtonEvents, handleProfileView }) => {
    const mainContainer = useRef();
    const { refs, inviteLink, refHandler, isConnected, userId, isMobile } = useContext(UserContext);
    
    useEffect(() => {
        if (isConnected && isMobile) refHandler();
        // console.log("friends rerendered");
    }, [isConnected, isMobile])
    
    return (
        <div ref={mainContainer} className='w-full h-dvh flex flex-col gap-4 px-4 py-4 relative z-[1]'>
            <div className="w-full h-[2rem] flex justify-between z-[8]">
                <div className="h-full aspect-square">
                    <Icons name="Logo" className="size-full" />
                </div>
                <div  onClick={(e) => handleProfileView("open profile page", e)} className='flex h-full items-center justify-end gap-2'>
                    <div className="h-full aspect-square">
                        <Icons name="Photo Frame" className="size-full" imagesrc={Profile1} />
                    </div>
                    <h1 className="text-white font-['Roboto_Mono'] uppercase text-[12px]">{name}</h1>
                </div>
            </div>
            <div className="w-full flex-col justify-start items-start gap-2 inline-flex relative blur-bg z-[8]">
                <div className="bg-card-1 absolute top-0 left-0 size-full flex justify-center items-center z-[1] ">
                    <div className="size-full relative bg-card-2 opacity-50"></div>
                </div>
                <div className="w-full  flex-col justify-start items-start flex px-4 py-4 relative z-[2]">
                    <div className="w-full flex-col justify-start items-start gap-2 flex font-['Violet_Sans']">
                        <div className="w-full flex-col justify-center items-start gap-2.5 flex">
                            <div className="px-2 py-1 bg-[#f8cf3f]/10 rounded-sm justify-start items-start gap-2.5 inline-flex">
                                <div className="text-center text-[#B073FF] text-[13px] font-normal ">Invite</div>
                            </div>
                        </div>
                        <div className="w-full text-[#efefe5] text-[30px] font-normal  leading-9">{`${refs.length} Friend${refs.length > 1 ? 's' : ''}`}</div>

                    </div>

                </div>
            </div>
            <div className='overflow-scroll pb-[5rem] flex flex-col gap-4 '>
                <div className="bg-friends-gradient w-full flex flex-col justify-start items-start gap-2 p-4 font-['Violet_Sans'] rounded-[3px]">
                    <div className="flex flex-col gap-1 pb-2">
                        <h1 className="text-white text-[20px] leading-[24px] tracking-[-0.5%]">Invite Friends</h1>
                        <p className="text-white text-[13px] leading-[16px]">You and your friends will receive bonuses</p>
                    </div>

                    <div className="flex gap-4 w-full p-2 items-center bg-[#121217] rounded-[3px]">
                        <div className="flex flex-col grow shrink basis-0 justify-start items-start p-2">
                            <p className="text-[#EFEFE5]/60 text-sm">Invite a friend</p>
                            <p className="text-[#EFEFE5]/60 text-sm"><span className='text-white text-base'>{refRewardBasic}</span> for you and your friend</p>
                        </div>
                        <div className="w-[3rem] aspect-square">
                            <img src={Coin} className='size-full object-contain object-center' />
                        </div>
                    </div>
                    <div className="flex gap-4 w-full p-2 items-center bg-[#121217] rounded-[3px]">
                        <div className="flex flex-col grow shrink basis-0 justify-start items-start p-2">
                            <p className="text-[#EFEFE5]/60 text-sm">Invite a friend with Telegram Premium</p>
                            <p className="text-[#EFEFE5]/60 text-sm"><span className='text-white text-base'>{refRewardPremium}</span> for you and your friend</p>
                        </div>
                        <div className="w-[3rem] aspect-square">
                            <img src={Box} className='size-full object-contain object-center' />
                        </div>
                    </div>
                </div>
                <button onClick={(e) => { handleButtonEvents("invite friends", e, inviteLink) }} className="relative ">

                    <div className="absolute top-0 left-0 size-full btn-cgpt-rounded"><div className="btn-cgpt-short relative size-full "></div></div>
                    <div className="absolute top-0 left-0 size-full btn-cgpt-rounded-2"><div className="btn-cgpt-2 relative size-full "></div></div>
                    {/* <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <filter className="flt_tag">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                            </filter>
                            <filter className="flt_tag_2">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                            </filter>
                        </defs>
                    </svg> */}
                    <div className="w-full relative py-4 px-6 flex justify-center items-center gap-2">
                        <p className="text-white text-sm font-['Roboto_Mono']">Share Link</p>
                    </div>
                </button>

                <div className="flex flex-col justify-start items-start w-full gap-2">


                    {(() => {
                        if (refs.length >= 1) {
                            return refs.map((ref, idx) => (
                                <div key={`friend-${idx}`} className="w-full justify-start items-center gap-4 inline-flex">
                                    <div className="w-[1.5rem] justify-start items-center gap-2.5 flex">
                                        <div className="text-stone-200/60 text-sm font-normal font-['Violet_Sans'] leading-tight">{idx + 1}</div>
                                    </div>
                                    <div className="grow shrink basis-0 text-stone-200 text-sm font-normal font-['Violet_Sans'] leading-tight">{ref.name}</div>
                                    <div className="justify-start items-center gap-2.5 flex">
                                        <div className="text-right text-stone-200/60 text-sm font-normal font-['Violet_Sans'] leading-tight">{formatNumber(ref.points)}</div>
                                    </div>
                                </div>
                            ))
                        } else {
                            return (
                                <div className="w-full p-3 justify-center items-center gap-3 inline-flex">
                                    <div className="grow shrink basis-0 justify-center items-center gap-2.5 flex">
                                        <div className="grow shrink basis-0 text-white text-center text-sm font-normal font-['Violet_Sans'] leading-[14.30px]">You have no referrals yet</div>
                                    </div>
                                </div>
                            )
                        }
                    })()}
                </div>
            </div>

        </div>
    );
}

export default FriendsPage;
